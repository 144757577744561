<template>
  <div class="firm-culture">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <join-list />
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import JoinList from "../../components/join/JoinList.vue";
export default {
  components: {
    PropertyTitle,
    JoinList,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "JOIN US",
      title: "我要应聘",
    };
  },
};
</script>
<style lang="less" scoped>
.property-title {
  margin-bottom: 67px;
}
</style>
