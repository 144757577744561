<template>
  <div class="join-list">
    <div class="join-input">
      <div class="join-select">
        <b-form-select
          v-model="selected"
          :options="options"
          @change="change"
        ></b-form-select>
      </div>
      <div class="seach-icon" @click="searchHandle">
        <i class="el-icon-search"></i>
      </div>
      <div class="join-input">
        <input
          v-model="search"
          type="text"
          class="form-control"
          placeholder="请输入职业关键词"
          @keyup.enter="searchHandle"
        />
      </div>
    </div>
    <div class="join-lists">
      <post-item
        v-for="(item, index) in lists"
        :key="item.id"
        :item="item"
        :index="index"
        :class="{ active: activeId === item.id }"
        @changeActiveId="changeActiveId"
      />
    </div>
  </div>
</template>
<script>
import { apiGetOffers, apiGetOfferSearch } from "../../api/index";
import PostItem from "./PostItem";
export default {
  components: { PostItem },
  data() {
    return {
      search: "",
      selected: "2",
      options: [
        { value: "2", text: "校园招聘" },
        { value: "1", text: "社会招聘" },
      ],
      lists: [],
      activeId: 0,
    };
  },
  methods: {
    init() {
      apiGetOffers().then((data) => {
        if (data.data.status == 1) {
          this.lists = data.data.coffer;
        }
        
      });
    },
    change() {
      apiGetOfferSearch(this.selected, this.search).then((data) => {
        if (data.data.status == 1) {
          this.lists = data.data.coffer;
        }
      });
    },
    searchHandle() {
      apiGetOfferSearch(this.selected, this.search).then((data) => {
        if (data.data.status == 1) {
          this.lists = data.data.coffer;
        }
      });
    },
    changeActiveId(id) {
      this.activeId = id;
      this.$router.push({
        name: "PostDetails",
        params: {
          id: id,
        },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less">
.join-list {
  width: 100%;
  padding: 20px 160px;
  .join-input {
    width: 100%;
    height: 100px;
    line-height: 100px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    .join-select {
      width: 346px;
      height: 100%;
      background-color: #e95506;
      text-align: center;
      margin-right: 2px;
      select {
        border: 0;
        background-color: #e95506;
        color: #fff;
        padding: 0 20px;
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
        font-size: 22px;
        option{
          font-size: 18px;
        }
      }
      select:hover {
        outline: 0;
        border: 0;
      }
      select:focus {
        outline: 0;
        border: 0;
      }
    }
    .seach-icon {
      width: 86px;
      height: 100%;
      background: #f8f8f8;
      text-align: right;
      padding-left: 51px;
      font-size: 28px;
      color: #ccc;
      cursor: pointer;
    }
    .join-input {
      display: 1;
      background-color: #f8f8f8;
      input {
        background-color: #f8f8f8;
        border: 0;
        padding: 0 28px;
        color: #000;
        font-size: 22px;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      }
      input[type="text"]:focus {
        outline: 0;
        border-color: #f8f8f8;
      }
    }
  }
  .join-lists {
    width: 100%;
    margin-top: 20px;
    .active {
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
      .item-name {
        color: #e95506 !important;
      }
    }
  }
}
</style>
