<template>
  <div class="item" @click="changeActiveId(item.id)">
    <div class="item-name">
      {{ item.position }}
    </div>
    <div class="address">
      <span>{{ item.area }} </span>
      <span style="margin: 0 5px">|</span>
      <span>{{ item.location }}</span>
    </div>
    <div class="item-content" v-html="item.duties"></div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    index: Number,
  },
  methods: {
    changeActiveId(id) {
      this.$emit("changeActiveId", id);
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  height:300px;
  margin-bottom: 40px;
  padding: 30px 100px;
  overflow: hidden;
  .item-name {
    font-size: 30px;
    font-family: "苹方-简 中黑体";
    src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
  }
  .address {
    font-size: 24px;
    margin: 25px 0;
    font-family: "苹方-简 常规体";
    src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
  }
  .item-content {
    font-size: 22px;
    color: #999;
  }
}
.item:hover {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
</style>
